import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import { CSVReader } from 'react-papaparse';
import Select from "react-select";
import useSound from 'use-sound';
import warnSfx from '../../assets/sounds/warning.wav';
import boopSfx from '../../assets/sounds/confirm.wav';
import RetailCycleCountTable from "./RetailCycleCountTable"
import RetailCycleCountRTable from "./RetailCycleCountRTable"
import { Card, Collapse } from "react-bootstrap";


import {
	Button,
	Form,
	Nav,
	Container,
	Row,
	Col,
	Table,
	Tab,
} from "react-bootstrap";

const moment = require('moment');
const createdDate = moment().format('MMMM Do YYYY, h:mm:ss a');

function retailCycleCount(props) {

	var _ = require("underscore");

	//Notification
	const notificationAlertRef = React.useRef(null);
	const notify = (place, type, msg) => {
		var type = type
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>{msg}</b>
					</div>
				</div>
			),
			type: type,
			icon: "nc-icon nc-bell-55",
			autoDismiss: 7,
		};
		notificationAlertRef.current.notificationAlert(options);
	};

	const [collapsibleAccordion, setCollapsibleAccordion] = React.useState(-1);

	const [playbackRate, setPlaybackRate] = React.useState(1.00);

	const [play] = useSound(boopSfx, {
		playbackRate,
		interrupt: true,
	});

	const soundClick = () => {
		play();
	};

	const [playWarn] = useSound(warnSfx, {
		playbackRate,
		interrupt: true,
	});

	// const soundClickWarn = () => {
	// 	// setPlaybackRate(playbackRate + 0.1);
	// 	playWarn()
	// };


	const [cycleCountData, setCycleCountData] = useState([]);
	const [sVCurrentLocation, setSVCurrentLocation] = useState([])
	const [cycleCountSkuQtyNotMatch, setCycleCountSkuQtyNotMatch] = useState([])
	const [inCycleCountNotInSv, setInCycleCountNotInSv] = useState([])
	const [inSvNotInCycleCount, setInSvNotInCycleCount] = useState([])
	const [matchingSkus, setMatchingSkus] = useState([])
	const [sVCurrentLocLength, setsVCurrentLocLength] = useState()
	const [storeSvLocation, setStoreSvLocation] = useState([])
	const [storeSvLocationLabel, setStoreSvLocationLabel] = useState()

	const [matchedSvAdds, setMatchedSvAdds] = useState([])
	const [matchedSvSubtracts, setMatchedSvSubtracts] = useState([])

	const [matchedTotalCogs, setMatchedTotalCogs] = useState([])
	const [diffTotalCogs, setDiffTotalCogs] = useState([])

	const handleSubmitStoreCount = async e => {
		e.preventDefault();
		props.showLoader()
		if (!cycleCountData.length) {
			notify("tc", "warning", `Add Todays Cycle Count CSV Before Downloading Current Store Inventory.`)
			return
		}
		console.log('STORE SUB', storeSvLocation)
		await axios.post('/api/cycle-count', { storeSvLocation })
			.then(async (response) => {
				if (response.status == 200) {
					const res = response.data
					const responseContainerMoreData = []
					const responseContainerMoreBinData = []
					const resCombine = []
					const responseContainerMain = []

					const responseContainer = []

					res.map((itemsWh) => {
						responseContainerMoreData.push(itemsWh.sku)
						responseContainerMoreBinData.push(itemsWh.sku)
						responseContainerMain.push({
							sku: itemsWh.sku,
							quantity: itemsWh.quantity,
						})
					})

					await axios.post('/api/cycle-location-data', [responseContainerMoreBinData, storeSvLocation])
						.then(async (response) => {
							const res = response.data

							res.map((itemsWh) => {
								responseContainerMain.push({
									sku: itemsWh.sku,
									quantity: itemsWh.quantity,
								})
							})
						}).catch(err => {
							console.log('Line 134',(err.response.data))
							notify("tc", "danger", `Response from storeLocation: ${err.response}.`)
						})

					await axios.post('/api/cycle-item-data', responseContainerMoreData)
						.then((response) => {
							const { Products } = response.data
							responseContainerMain.forEach((skus) => {
								var matchedSk = _.findWhere(Products, { Sku: skus.sku })
								resCombine.push({
									sku: matchedSk.Sku,
									quantity: skus.quantity,
									classification: matchedSk.Classification,
									cost: matchedSk.Cost
								})
							})
							///SET SV CURRENT WH STORE DATA HERE
							setSVCurrentLocation(resCombine)
							setsVCurrentLocLength(resCombine.length)
							notify("tc", "success", `Response from storeLocation`)
							// soundClickWarn()
							soundClick()
							props.hideLoader()
						}).catch(err => {
							console.log('Line 158',(err.response.data))
							notify("tc", "danger", `Response from storeLocation: ${err.response}.`)
						})

				}
			}).catch(err => {
				console.log('Line 164',(err.response.data))
				notify("tc", "danger", `Response from storeLocation: ${err.response}.`)
			})
	}

	const handleOnDrop = (data) => {
		const collectSkus = []
		console.log('---------------------------')
		data.forEach((skus) => {
			collectSkus.push({
				sku: skus.data.sku,
				quantity: parseInt(skus.data.quantity, 10)
			})
		})
		setCycleCountData(collectSkus)
		console.log('---------------------------')
	};

	const handleOnError = (err, file, inputElem, reason) => {
		console.log(err);
	};

	const handleOnRemoveFile = (data) => {
		console.log('---------------------------');
		console.log(data);
		setCycleCountData([])
		console.log('---------------------------');
	}

	const handleRunCrossCheck = () => {
		const cycleSkuMatch = cycleCountData.filter(o => sVCurrentLocation.some(i => i.sku === o.sku)).map((s) => s)
		// const cycleNoMatch = cycleCountData.filter(o => !sVCurrentLocation.some(i => i.sku === o.sku)).map((s) => s)
		const matchedSkuNotQty = []
		const matchedSvAdds = []
		const matchedSvSubtracts = []

		function diffCheck(itemQty, svQty, item) {
			if (itemQty > svQty) {
				const greater = Math.abs(itemQty - svQty)
				if (greater !== 0) {
					matchedSvAdds.push({
						"Sku": item,
						"WarehouseId": storeSvLocation,
						"LocationCode": "GENERAL",
						"Quantity": greater,
						"Reason": "Cycle-Count-Add"
					})
				}
				return '+'
			} else {
				const less = Math.abs(svQty - itemQty)
				if (less !== 0) {
					matchedSvSubtracts.push({
						///REMOVES
						"Sku": item,
						"WarehouseId": storeSvLocation,
						"LocationCode": "GENERAL",
						"Quantity": less,
						"Reason": "Cycle-Count-Remove"
					})
					return '-'
				}
			}
		}

		const cycleMatchedCogs = []
		const cycleDiffCogs = []

		cycleSkuMatch.forEach((item) => {
			var sVsku = _.findWhere(sVCurrentLocation, { sku: item.sku })

			let diffQtySymb = diffCheck(item.quantity, sVsku.quantity, item.sku);
			
			let diffSym;
			if (diffQtySymb) {
				diffSym = diffQtySymb.toString()
			}

			const diffQty = Math.abs(item.quantity - sVsku.quantity);
			const cogs = diffQty * sVsku.cost
			//setup total COGS for reduce
			cycleMatchedCogs.push(sVsku)
			cycleDiffCogs.push(cogs)
			// var cogsParse = cogs.toFixed(2);
			const diffRes = `${diffSym}${diffQty}`
			if (sVsku.sku === item.sku && sVsku.quantity !== item.quantity) {
				matchedSkuNotQty.push({
					sku: item.sku,
					cycleqty: item.quantity,
					svqty: sVsku.quantity,
					diff: diffRes,
					classification: sVsku.classification,
					cogs: cogs.toFixed(2)
				})
			}
		})

		var matchedCogs = cycleMatchedCogs.map(item => item.cost).reduce((prev, next) => prev + next)
		var diffCogs = cycleDiffCogs.map(item => item).reduce((prev, next) => prev + next)

		var matchedCogsSend = matchedCogs.toFixed(2)
		var diffCogsSend = diffCogs.toFixed(2)


		const cycleCheckArr = []
		const svCheckArr = []

		const notFoundInSv = []
		const notFoundInCycleCount = []

		cycleCountData.forEach((cycleSku) => {
			cycleCheckArr.push(cycleSku.sku)
		})

		sVCurrentLocation.forEach((svSku) => {
			svCheckArr.push(svSku.sku)
		})

		function inAButNotInB(A, B) {
			return _.filter(A, function (a) {
				return !_.contains(B, a);
			})
		}

		const inCycleCountNotInSvResult = inAButNotInB(cycleCheckArr, svCheckArr)
		const inSvCountNotInCycleCountResult = inAButNotInB(svCheckArr, cycleCheckArr)

		inCycleCountNotInSvResult.forEach((notFound) => {
			var notFountSvSku = _.findWhere(cycleCountData, { sku: notFound })
			notFoundInSv.push(notFountSvSku)
		})

		inSvCountNotInCycleCountResult.forEach((notFound) => {
			var notFountCycleCountSku = _.findWhere(sVCurrentLocation, { sku: notFound })
			notFoundInCycleCount.push(notFountCycleCountSku)
		})
		
		// console.log('svAdds', matchedSvAdds.length, 'svSubs', matchedSvSubtracts.length)
		setMatchedTotalCogs(matchedCogsSend)
		setDiffTotalCogs(diffCogsSend)
		notFoundInSv.map((adds) => {
			matchedSvAdds.push({
				"Sku": adds.sku,
				"WarehouseId": storeSvLocation,
				"LocationCode": "GENERAL",
				"Quantity": adds.quantity,
				"Reason": "Cycle-Count-Add"
			})
		})

		notFoundInCycleCount.map((subs) => {
			matchedSvSubtracts.push({
				"Sku": subs.sku,
				"WarehouseId": storeSvLocation,
				"LocationCode": "GENERAL",
				"Quantity": subs.quantity,
				"Reason": "Cycle-Count-Remove"
			})
		})

		// console.log(matchedSvAdds, matchedSvSubtracts, notFoundInSv, notFoundInCycleCount, 'NOMAT')
		console.log('svAdds1', matchedSvAdds.length, 'svSubs1', matchedSvSubtracts.length)

		setMatchedSvAdds(matchedSvAdds)
		setMatchedSvSubtracts(matchedSvSubtracts)

		setCycleCountSkuQtyNotMatch(matchedSkuNotQty)

		setInCycleCountNotInSv(notFoundInSv)
		setInSvNotInCycleCount(notFoundInCycleCount)
	}

	const handleProcessSkusAdds = async () => {
		if (matchedSvAdds.length > 100) {
			return notify("tc", "danger", `Currently only 100 SKUs are allowed to update at this time`)
		}
		await axios.post('/api/cycle-update-add', { matchedSvAdds })
			.then(async (response) => {
				console.log('addsRes:', response.data)
				if (response.status === 200) {
					soundClick()
					notify("tc", "success", `SkuVault QTY Has Been Added.`)
				} else {
					notify("tc", "danger", `Response from storeLocation: ${response.data}.`)
					soundClickWarn()
				}
			}).catch(err => {
				console.log((err.response.data))
				notify("tc", "danger", `Response from storeLocation: ${err.response}.`)
			})
	}

	const handleProcessSkusRemoves = async () => {

		if (matchedSvSubtracts.length > 100) {
			return notify("tc", "danger", `Currently only 100 SKUs are allowed to update at this time`)
		}

		await axios.post('/api/cycle-update-remove', { matchedSvSubtracts })
			.then(async (response) => {
				console.log('addsRes:', response.data)
				if (response.status == 200) {
					soundClick()
					notify("tc", "success", `SkuVault QTY Has Been Removed.`)
				} else {
					notify("tc", "danger", `Response from storeLocation: ${response.data}.`)
					soundClickWarn()
				}
			}).catch(err => {
				console.log((err.response.data))
				notify("tc", "danger", `Response from storeLocation: ${err.response}.`)
			})
	}

	return (
		<>
			<div className="rna-container">
				<NotificationAlert ref={notificationAlertRef} />
			</div>
			<Container fluid className="pl-0 pr-0 ml-0 mr-0">
				<Row>
					<Col md="12" className="pl-0 pr-0 ml-0 mr-0">
						<Card.Body className="">
							<Tab.Container
								id="icons-tabs-example"
								defaultActiveKey="info-icons"
							>
								<Nav role="tablist" variant="tabs" className="mb-0 p-0">
									<Nav.Item>
										<Nav.Link eventKey="info-icons">
											<i className="fas fa-barcode"></i>
											&nbsp;Retail Store Cycle Count Helper
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="account-icons">
											<i className="fas fa-upload"></i> Update SkuVault
										</Nav.Link>
									</Nav.Item>
								</Nav>
								<Tab.Content>
									<Tab.Pane eventKey="info-icons">
										<Card className="mb-0 pb-0 p-4 pl-2 bg-border-top-0 border-left-1 border-right-1 border-bottom-1 tab-radius-top-left">
											<Row>
												<Col md="12" className="ml-0 mr-0">
													<Card.Body className="border-left-0 border-0 d-flex justify-content-center">
														<Col md="8">
															<Row>
																<Col md="3">
																	<Form.Group>
																		<Select
																			name="Payout Method"
																			// value={payoutPercentage}
																			onChange={(value) => {
																				setStoreSvLocation(value.value)
																				setStoreSvLocationLabel(value.label);
																			}}
																			options={[
																				{ value: 48571, label: "Millcreek" },
																				{ value: 78390, label: "Downtown" },
																				{ value: 48572, label: "Ogden" },
																				// { value: 4, label: "Millcreek" },
																				// { value: 7, label: "Downtown" },
																				// { value: 5, label: "Ogden" },
																			]}
																			placeholder="Store Location"
																		/>
																	</Form.Group>
																</Col>
																<Col md="9">
																	<Button
																		className="btn-fill btn-neutral btn-wd bg-secondary text-light ml-4 pt-2 pb-2"
																		type="submit"
																		variant="default"
																		onClick={handleSubmitStoreCount}
																	>
																		Step-1 Load Selected WH Inventory
																	</Button>
																	<Button
																		className="bg-info text-light p-2 ml-4"
																		type="submit"
																		onClick={handleRunCrossCheck}
																	>
																		Step-2 Run Cross Check Process
																	</Button>
																</Col>
															</Row>
														</Col>
													</Card.Body>
												</Col>
											</Row>
											<Row>
												<Col md="6" className="ml-0 mr-0 pt-0 pb-0">
													<Card className="mt-2 card-custom text-center">
														<Card.Header>
															<Card.Title as="h4">Absolute Cycle Count COGS
															</Card.Title>
														</Card.Header>
														<Card.Body className="ml-2 mr-2 d-flex justify-content-center">
															<Row>
																<h4 className="text-center text-success font-weight-bold  pt-0 mt-0">$ {matchedTotalCogs}</h4>
															</Row>
														</Card.Body>
													</Card>
												</Col>
												<Col md="6" className="ml-0 mr-0 pt-0 pb-0">
													<Card className="mt-2 card-custom text-center">
														<Card.Header>
															<Card.Title as="h4">Absolute Cycle Count Diff COGS
															</Card.Title>
														</Card.Header>
														<Card.Body className="ml-2 mr-2 d-flex justify-content-center">
															<Row>
																<h4 className="text-center text-danger font-weight-bold pt-0 mt-0">$ {diffTotalCogs}</h4>
															</Row>
														</Card.Body>
													</Card>
												</Col>
											</Row>
											<Row>
												<Col md="4" className="ml-0 mr-0 ">
													<Card className="mt-2 card-custom">
														<Card.Header>
															<Card.Title as="h4">Todays Cycle Count:
																{cycleCountData.length > 0 && (
																	<medium className="pl-2 text-danger">SKU Count: {cycleCountData.length} </medium>
																)}
															</Card.Title>
														</Card.Header>
														<Card.Body className="ml-2 mr-2">
															<Row>
																<Col md="12">
																	<CSVReader
																		onDrop={handleOnDrop}
																		onError={handleOnError}
																		config={{ header: true }}
																		addRemoveButton
																		onRemoveFile={handleOnRemoveFile}
																		style={{
																			dropArea: {
																				borderColor: '#007bff',
																				borderRadius: 10,
																				height: 80,
																			},
																			dropAreaActive: {
																				borderColor: '#e83e8c',
																			},
																			dropFile: {
																				width: 300,
																				height: 100,
																			},
																			fileSizeInfo: {
																				color: '#fff',
																				backgroundColor: '#28a745',
																				borderRadius: 3,
																				lineHeight: 2,
																				marginBottom: '0.5em',
																				padding: '0 0.4em',
																			},
																			fileNameInfo: {
																				color: '#fff',
																				backgroundColor: '#6c757d',
																				borderRadius: 3,
																				fontSize: 14,
																				lineHeight: 2,
																				padding: '0 0.4em',
																			},
																			removeButton: {
																				color: 'red',
																			},
																			progressBar: {
																				padding: '0.5em 0',
																				padding: '0 0.4em',
																				backgroundColor: 'orange',
																			}
																		}}
																	>
																		<span>Drop cycle count CSV or click to select local.</span>
																	</CSVReader>

																</Col>
															</Row>
															<Row>
																<Col md="12">
																	<Card className="regular-table-with-color mt-4 card-custom-one">
																		<Card.Header>
																			<Card.Title as="h4">Cycle Count Sheet:</Card.Title>
																		</Card.Header>
																		<Card.Body className="table-responsive p-0 p-">
																			<Table className="table-hover">
																				<thead>
																					<tr key='pick-1'>
																						<th>SKU</th>
																						<th>Quantity</th>
																					</tr>
																				</thead>
																				<tbody>
																					{cycleCountData.map((item, i) =>
																						<RetailCycleCountTable
																							id={item.sku}
																							sku={item?.sku}
																							quantity={item.quantity}
																						/>
																					)}
																				</tbody>
																			</Table>
																		</Card.Body>
																	</Card>
																</Col>
															</Row>
															<Row>
															</Row>
														</Card.Body>
													</Card>
												</Col>
												<Col md="8" className="ml-0 mr-0">
													<Card className="mt-2 pb-3 card-custom">
														<Card.Header>
															<Card.Title as="h4">Cross Check cycle count against SkuVault Qty:
															</Card.Title>
														</Card.Header>
														<Card.Body className="ml-2 mr-2">
															<Row>
																{sVCurrentLocLength && (
																	<medium className="pl-3 pt-2 pb-0 mb-0">As of {`Date:${createdDate}`} there are {sVCurrentLocLength} skus found in {storeSvLocationLabel}</medium>
																)}
															</Row>
															<div className="accordions" id="accordion">
																<Card className='mt-4 mb-4'>
																	<Card.Header>
																		<Card.Title as="h4">
																			<a
																				data-toggle="collapse"
																				aria-expanded={collapsibleAccordion === 1}
																				href="#pablo"
																				onClick={(e) => {
																					e.preventDefault();
																					collapsibleAccordion === 1
																						? setCollapsibleAccordion(-1)
																						: setCollapsibleAccordion(1);
																				}}
																			>
																				<medium className="pl-2">Not matching skuvault quantity {storeSvLocationLabel && (<medium>at {storeSvLocationLabel} location</medium>)}</medium>
																			</a>
																		</Card.Title>
																	</Card.Header>
																	<Collapse
																		id="collapseOneHover"
																		in={collapsibleAccordion === 1}
																	>
																		<Card.Body>
																			<Row>
																				<Col md="12">
																					{cycleCountSkuQtyNotMatch.length && (
																						<medium className="pl-2 text-primary">{cycleCountSkuQtyNotMatch.length} skus's cycle count qty does not match SV qty.</medium>
																					)}
																					<Table className="table-hover">
																						<RetailCycleCountRTable
																							data={cycleCountSkuQtyNotMatch}
																						/>
																					</Table>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Collapse>
																</Card>
																<Card>
																	<Card.Header>
																		<Card.Title as="h4">
																			<a
																				data-toggle="collapse"
																				href="#pablo"
																				aria-expanded={collapsibleAccordion === 3}
																				onClick={(e) => {
																					e.preventDefault();
																					collapsibleAccordion === 3
																						? setCollapsibleAccordion(-1)
																						: setCollapsibleAccordion(3);
																				}}
																			>
																				<medium className="pl-2">Missing from {storeSvLocationLabel && (<medium>at {storeSvLocationLabel} location</medium>)}</medium>
																			</a>
																		</Card.Title>
																	</Card.Header>
																	<Collapse
																		className="card-collapse"
																		id="collapseThreeHover"
																		in={collapsibleAccordion === 3}
																	>
																		<Card.Body>
																			<Row>
																				<Col md="12">
																					{inSvNotInCycleCount.length && (
																						<medium className="pl-2 text-primary">{inSvNotInCycleCount.length} skus's were found in SV {storeSvLocationLabel} location and not on cycle count.</medium>
																					)}
																					<Table className="table-hover">
																						<thead>
																							<tr>
																								<th>SKU</th>
																								<th>SV Qty:</th>
																								<th>SV Classification:</th>
																								<th>SV Cost:</th>
																							</tr>
																						</thead>
																						<tbody>
																							{inSvNotInCycleCount.map((item, i) =>
																								<RetailCycleCountTable
																									id={item.sku}
																									sku={item.sku}
																									quantity={item.quantity}
																									classification={item.classification}
																									cost={item.cost}
																								/>
																							)}
																						</tbody>
																					</Table>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Collapse>
																</Card>
																<Card className='mt-3'>
																	<Card.Header>
																		<Card.Title as="h4">
																			<a
																				data-toggle="collapse"
																				aria-expanded={collapsibleAccordion === 2}
																				href="#pablo"
																				onClick={(e) => {
																					e.preventDefault();
																					collapsibleAccordion === 2
																						? setCollapsibleAccordion(-1)
																						: setCollapsibleAccordion(2);
																				}}
																			>
																				<medium className="pl-2">Found at {storeSvLocationLabel && (<medium>at {storeSvLocationLabel} location</medium>)}</medium>
																			</a>
																		</Card.Title>
																	</Card.Header>
																	<Collapse
																		className="card-collapse"
																		id="collapseTwoHover"
																		in={collapsibleAccordion === 2}
																	>
																		<Card.Body>
																			<Row>
																				<Col md="12">
																					{inCycleCountNotInSv.length && (
																						<medium className="pl-2 text-primary">{inCycleCountNotInSv.length} skus's cycle counted and not found in SV {storeSvLocationLabel} location.</medium>
																					)}
																					<Table className="table-hover">
																						<thead>
																							<tr>
																								<th>SKU</th>
																								<th>Cycle Qty:</th>
																							</tr>
																						</thead>
																						<tbody>
																							{inCycleCountNotInSv.map((item) =>
																								<RetailCycleCountTable
																									id={item.sku}
																									sku={item.sku}
																									quantity={item.quantity}
																								/>
																							)}
																						</tbody>
																					</Table>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Collapse>
																</Card>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Card>
									</Tab.Pane>
									<Tab.Pane eventKey="account-icons">
										<Card className="mb-0 pb-0 p-4 pl-2 bg-border-top-0 border-left-1 border-right-1 border-bottom-1 tab-radius-top-left">
											<Row>
												<Card.Body className="border-left-0 border-0 d-flex justify-content-center">
													<Row>
														<Button
															className="bg-danger text-light p-2 ml-4"
															type="submit"
															variant="danger"
															onClick={handleProcessSkusAdds}
														>
															Step-3 PROCESS ADDS
														</Button>
														<Button
															className="bg-danger text-light p-2 ml-4"
															type="submit"
															variant="danger"
															onClick={handleProcessSkusRemoves}
														>
															Step-4 PROCESS REMOVES
														</Button>
													</Row>
												</Card.Body>
												<Col md="12" className="ml-0 pl-0 mr-0">
													<Card className="mt-4 card-custom">
														<Card.Header>
															<Card.Title as="h4">UPDATE SKUVAULT INVENTORY:
															</Card.Title>
														</Card.Header>
														<Card.Body className="ml-2 mr-2">
															<Row>
																{sVCurrentLocLength && (
																	<medium className="pl-3 pt-2 pb-0 mb-0">As of {`Date:${createdDate}`} there are {sVCurrentLocLength} skus found in {storeSvLocationLabel}</medium>
																)}
															</Row>
															<div className="accordions" id="accordion">
																<Card className='mt-3'>
																	<Card.Header>
																		<Card.Title as="h4">
																			<a
																				data-toggle="collapse"
																				aria-expanded={collapsibleAccordion === 4}
																				href="#pablo"
																				onClick={(e) => {
																					e.preventDefault();
																					collapsibleAccordion === 4
																						? setCollapsibleAccordion(-1)
																						: setCollapsibleAccordion(4);
																				}}
																			>
																				<medium className="pl-2">SKU + ADD REPORT  {storeSvLocationLabel && (<medium>at {storeSvLocationLabel} location</medium>)}</medium>
																			</a>
																		</Card.Title>
																	</Card.Header>
																	<Collapse
																		id="collapseOneHover"
																		in={collapsibleAccordion === 4}
																	>
																		<Card.Body>
																			<Row>
																				<Col md="12">
																					<Card.Body className="table-responsive p-0 p-0 card-custom">
																						{matchedSvAdds.length && (
																							<medium className="pl-2 text-primary">{matchedSvAdds.length} skus's inventory will be added to {storeSvLocationLabel} GENERAL location.</medium>
																						)}
																						<Table className="table-hover">
																							<thead>
																								<tr>
																									<th>SKU</th>
																									<th>SV + Qty:</th>
																								</tr>
																							</thead>
																							<tbody>
																								{matchedSvAdds.map((item, i) =>
																									<RetailCycleCountTable
																										id={item.Sku}
																										sku={item.Sku}
																										quantity={item.Quantity}
																									/>
																								)}
																							</tbody>
																						</Table>
																					</Card.Body>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Collapse>
																</Card>
																<Card>
																	<Card.Header>
																		<Card.Title as="h4">
																			<a
																				data-toggle="collapse"
																				href="#pablo"
																				aria-expanded={collapsibleAccordion === 5}
																				onClick={(e) => {
																					e.preventDefault();
																					collapsibleAccordion === 5
																						? setCollapsibleAccordion(-1)
																						: setCollapsibleAccordion(5);
																				}}
																			>
																				<medium className="pl-2">SKU - SUBTRACTION REPORT {storeSvLocationLabel && (<medium>at {storeSvLocationLabel} location</medium>)}</medium>
																			</a>
																		</Card.Title>
																	</Card.Header>
																	<Collapse
																		className="card-collapse"
																		id="collapseThreeHover"
																		in={collapsibleAccordion === 5}
																	>
																		<Card.Body>
																			<Row>
																				<Col md="12">
																					{matchedSvSubtracts.length && (
																						<medium className="pl-2 text-primary">{matchedSvSubtracts.length} skus's inventory will be removed from {storeSvLocationLabel} GENERAL location.</medium>
																					)}
																					<Table className="table-hover">
																						<thead>
																							<tr>
																								<th>SKU</th>
																								<th>SV - Qty:</th>
																							</tr>
																						</thead>
																						<tbody>
																							{matchedSvSubtracts.map((item, i) =>
																								<RetailCycleCountTable
																									id={item.Sku}
																									sku={item.Sku}
																									quantity={item.Quantity}
																								/>
																							)}
																						</tbody>
																					</Table>
																				</Col>
																			</Row>
																		</Card.Body>
																	</Collapse>
																</Card>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</Card>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</Card.Body>
					</Col>
				</Row>
			</Container>
		</>
	);
}

const mapDispatchToProps = {
	showLoader,
	hideLoader
}

export default connect(undefined, mapDispatchToProps)(retailCycleCount);