import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SweetAlert from "react-bootstrap-sweetalert";

import {
    Badge,
    Button,
    Card,
    Form,
    InputGroup,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";


function ReturnGoodsTable({ id, item_id, title, price, created_by, p_notes, date_created, handlePrinted, setPrinted, printed, itemType }) {

    const date = date_created.match(/(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})/)
    var dateFormated = `${date[2]}-${date[3]}-${date[1]}`
    
    // const customSKU = 'RGP' + item_id;
    const customSKU = itemType === "dbItem" ? "DBP" + item_id : "RGP" + item_id;

    function handlePrint() {
        const pTitle = title.replace(/[!@?#$()%^&*]/g, "");
        const url = `/printrpg/${customSKU}/${price}/${pTitle}/${created_by}/${dateFormated}`;
        window.open(url);
    }
    return (
        <>
            <tbody key={id}>
                <tr>
                    <td>{customSKU}</td>
                    <td>{title}</td>
                    <td>${price}</td>
                    <td>{created_by}</td>
                    <td>{p_notes}</td>
                    <td>{dateFormated}</td>
                    <td className="text-right">
                        <OverlayTrigger
                            href="#pablo"
                            // onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">{printed ? "Already Printed" : "Print"}</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                variant={printed ? "success" : "danger"}
                                onClick={() => {
                                    handlePrinted(id)
                                    handlePrint()
                                }
                                }
                            >
                                <i className="fas fa-tags mr-1"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            </tbody>
        </>
    );
}
export default ReturnGoodsTable;