import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../store/reducer';
import UsersList from './UsersList';
import Select from "react-select";
import ReturnGoodsAddItem from "./ReturnGoodsAddItems"
import ReturnGoodsTable from "./ReturnGoodsTable"
import { useSelector } from 'react-redux';

import {
    Alert,
    Badge,
    Button,
    Card,
    Form,
    Media,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    FormGroup,
    FormControl,
    Table,
    Tab,
    OverlayTrigger,
    Tooltip,
    InputGroup
} from "react-bootstrap";



function ReturnGoods(props) {
    const username = useSelector((reduxState) => reduxState.username);
    console.log("username", username)
    const notificationAlertRef = React.useRef(null);

    const [itemType, setItemType] = useState("bcItem"); 

    const notify = (place, type, msg) => {
        var type = type
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    // const handleUsernameChange = e => setUsername(e.target.value);

    // Validators for Add Item component 
    const isRequired = (value) => value !== null && value !== "" && value;
    const isNumber = (value) => !isNaN(value) && value !== "";

    //add item + validator state
    const [productTitle, setProductTitle] = useState("");
    const [titleRequiredState, setTitleRequiredState] = useState(true);
    const [price, setPrice] = useState("");
    const [priceRequiredState, setPriceRequiredState] = useState(true);
    const [productNotes, setProductNotes] = useState("");

    //select options WIHTOUT validators 
    const [category, setCategory] = useState("");
    const [catLabel, setCatLabel] = useState("");
    const [brand, setBrand] = useState("");
    const [brandLabel, setBrandLabel] = useState("");

    //select option validators

    const [qoh, setqoh] = useState(1);

    //add item handlers
    const clearTitle = (e) => {
        setProductTitle("")
        console.log('a')
    }

    const handleProductTitle = (e) => {
        setProductTitle(e.target.value)
        isRequired(e.target.value) ? setTitleRequiredState(true) : setTitleRequiredState(false);
    }
    const handlePrice = (e) => {
        setPrice(e.target.value)
        isNumber(e.target.value) ? setPriceRequiredState(true) : setPriceRequiredState(false);
    }
    const handleProductNotes = e => setProductNotes(e.target.value);

    const clearForm = () => {
        document.getElementById("add-item-form").reset();
    }

    const handleSubmitItem = e => {

        e.preventDefault();
        let formNotFilled = 0
        if (!titleRequiredState || !isRequired(productTitle)) {
            setTitleRequiredState(false);
            formNotFilled++
        }
        if (!priceRequiredState || !isRequired(price)) {
            setPriceRequiredState(false);
            formNotFilled++
        }
        if (formNotFilled > 0) {
            return
        }
        else {
            setTitleRequiredState(true);
            setPriceRequiredState(true);
        }

        props.showLoader()
        axios.post('/api/item', { productTitle, price, productNotes, username, category, brand, catLabel, qoh, itemType})
            .then(() => {
                props.hideLoader()
                clearForm()
                setProductTitle("")
                setTitleRequiredState(true)
                setPrice("")
                setPriceRequiredState(true)
                setProductNotes("")
                setCategory("")
                setBrand("")
                notify("tc", "success", "Item Created!")
                window.location.reload(false);
            }).catch(err => {
                props.hideLoader()
                notify("tc", "danger", "Error attempting to create item, please try again. ")
                console.log(err)
            })
    }

    //get last 100 tags to print on print tab
    const [resultsPrintTable, setResultsPrintTable] = useState([]);
    const handleGetNormalItems = async () => {
        await axios.get('/api/items')
            .then((response) => {
                setResultsPrintTable(response.data)
            }).catch(err => {
                console.log((err.response.data))
            })
    }

    // Search query ported from Consignor component
    const [searchQuery, setSearchQuery] = useState('');
    const [fnReqSearchQueryState, setfnReqSearchQueryState] = useState(true);

    const handleSearchQuery = (e) => {
        setSearchQuery(e.target.value.trim());
        e.target.value ? setfnReqSearchQueryState(false) : setfnReqSearchQueryState(true);
    }

    const search = async () => {
        await axios.post('/api/items-search', { searchQuery })
            .then((response) => {
                setResultsPrintTable(response.data)
            }).catch(err => {
                console.log((err.response.data))
            })
    }

    const handleSubmitSearch = e => {
        e.preventDefault();
        if (!fnReqSearchQueryState) {
            search()
            return setfnReqSearchQueryState(false);
        }
        else {
            setfnReqSearchQueryState(true);
        }
    }

    // hook to set tag as printed or not
    const [printed, setPrinted] = useState(undefined);
    const handlePrinted = async (id) => {
        var printTrue = true
        await axios.post('/api/print', { printTrue, id })
            .then((response) => {
                handleGetNormalItems()
                setPrinted(response.data)
                console.log('response', response.data)
            }).catch(err => {
                console.log((err.response.data))
            })
    }

    return (
        <>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Container>
                <Row>
                    <Col md="12">
                        <Card.Body className="">
                            <Tab.Container
                                id="icons-tabs-example"
                                defaultActiveKey="info-icons"
                            >
                                <Nav justify role="tablist" variant="pills" className="mb-0 p-0 ">
                                    <Nav.Item>
                                        <Nav.Link eventKey="info-icons">
                                            Add RG Item
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            onClick={handleGetNormalItems}
                                            eventKey="account-icons">
                                            Print RG Tags
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="info-icons">
                                        <Card className="">
                                            <Row>
                                                <Col className="pt-4 pb-4 mt-4" md="12">
                                                    <ReturnGoodsAddItem
                                                        setCategory={setCategory}
                                                        setCatLabel={setCatLabel}
                                                        setBrand={setBrand}
                                                        setBrandLabel={setBrandLabel}
                                                        titleRequiredState={titleRequiredState}
                                                        priceRequiredState={priceRequiredState}
                                                        setqoh={setqoh}
                                                        handleProductNotes={handleProductNotes}
                                                        handleSubmitItem={handleSubmitItem}
                                                        handleProductTitle={handleProductTitle}
                                                        handlePrice={handlePrice}
                                                        setItemType={setItemType}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="account-icons">
                                        <Card className="">
                                            {/* <Card.Title as="h5" className="pl-3 pt-3"></Card.Title> */}
                                            <p className="card-category font-weight-bold h6 pb-4 pl-4 pt-4">
                                                Lookup RGP Tag:
                                            </p>
                                            <Col md="12">
                                                <Form action="#" method="#">
                                                    <Row>
                                                        <Col md="6">
                                                            <Form.Group>
                                                                <InputGroup>
                                                                    <i className="mt-1 ml-1 nc-icon nc-zoom-split pl-2 pt-2 pr-2 border-top border-left border-bottom"></i>
                                                                    <Form.Control
                                                                        defaultValue=""
                                                                        placeholder="Seach by SKU OR Title"
                                                                        type="text"
                                                                        className="mt-1"
                                                                        onChange={handleSearchQuery}
                                                                    ></Form.Control>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="6">
                                                        <Form.Group>
                                                            <label>Item Type for Printing</label>
                                                            <div>
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Backcountry (RGP)"
                                                                    name="printItemType"
                                                                    value="bcItem"
                                                                    checked={itemType === "bcItem"}
                                                                    onChange={(e) => setItemType(e.target.value)}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Discount Bin (DBP)"
                                                                    name="printItemType"
                                                                    value="dbItem"
                                                                    checked={itemType === "dbItem"}
                                                                    onChange={(e) => setItemType(e.target.value)}
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                        </Col>                                                        
                                                        <Col md="6">
                                                            <Row>
                                                                <Col md="3" className="pl-4">
                                                                    <Button
                                                                        className="pl-5 pr-5"
                                                                        type="submit"
                                                                        variant="primary"
                                                                        onClick={handleSubmitSearch}
                                                                    >
                                                                        Search
                                                                    </Button>
                                                                </Col>
                                                                <Col md="9" className="pl-5">
                                                                    <Button
                                                                        className="pl-5 pr-5"
                                                                        variant="outline"
                                                                        onClick={handleGetNormalItems}
                                                                        type="reset"
                                                                        defaultValue="Reset"
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Row>
                                                <Col md="12">
                                                    <Card className="card-plain table-plain-bg">
                                                        <Card.Header>
                                                            <p className="card-category font-weight-bold h6 pl-2 pt-4 pb-2">
                                                                Last 100 tags:
                                                            </p>
                                                        </Card.Header>
                                                        <Card.Body className="table-responsive p-0">
                                                            <Table className="table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ItemID</th>
                                                                        <th>Product Name</th>
                                                                        <th>Price</th>
                                                                        <th>Store</th>
                                                                        <th>Initials</th>
                                                                        <th>Date Created</th>
                                                                        <th width="50px" className="text-center">Print</th>
                                                                    </tr>
                                                                </thead>
                                                                {resultsPrintTable.map((table, i) =>
                                                                    <ReturnGoodsTable
                                                                        key={i}
                                                                        id={table.id}
                                                                        item_id={table.item_id}
                                                                        title={table.title}
                                                                        price={table.price}
                                                                        created_by={table.created_by}
                                                                        p_notes={table.p_notes}
                                                                        date_created={table.date_created}
                                                                        printed={table.printed}
                                                                        handlePrinted={handlePrinted}
                                                                        setPrinted={setPrinted}
                                                                        itemType={itemType}
                                                                    />
                                                                )}
                                                            </Table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapDispatchToProps = {
    showLoader,
    hideLoader
}

export default connect(undefined, mapDispatchToProps)(ReturnGoods);